import './App.css';
// Import the logo - adjust the path based on your file location
import kamaLogo from './kamalogo.png';
// Import icons from react-icons
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Helmet } from 'react-helmet-async';

function App() {
  return (
    <div className="App">
      <Helmet>
        {/* Primary Meta Tags */}
        <title>Kamaconcept</title>
        <meta name="title" content="Kamaconcept" />
        <meta name="description" content="Discover Kamaconcept" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://kamaconcept.com/" />
        <meta property="og:title" content="Kama Concept" />
        <meta property="og:description" content="Discover Kamaconcept" />
        <meta property="og:image" content={kamaLogo} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://kamaconcept.com/" />
        <meta property="twitter:title" content="Kama Concept" />
        <meta property="twitter:description" content="Discover Kamaconcept" />
        <meta property="twitter:image" content={kamaLogo} />

        {/* Additional Meta Tags */}
        <meta name="keywords" content="kamaconcept" />
        <meta name="author" content="Kama Concept" />
        <link rel="canonical" href="https://kamaconcept.com/" />
      </Helmet>

      <main className="landing-container">
        <div className="logo-container">
          <img src={kamaLogo} alt="Kama Logo" className="kama-logo" />
        </div>
        
        <div className="social-icons">
          <a href="https://instagram.com/kamaconcept" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icon" />
          </a>
          <a href="mailto:kamaconcept@yahoo.com">
            <MdEmail className="icon" />
          </a>
          <a href="https://wa.me/+12348558484" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="icon" />
          </a>
        </div>
      </main>
    </div>
  );
}

export default App;
